var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Requested products")))])])]), _c('div', {
    staticClass: "card-body detail order-table"
  }, [_c('datatable', {
    ref: "secondmentsOrdersTable",
    attrs: {
      "table-props": _vm.tableProps,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "total": _vm.total,
      "thead-hidden": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row-details",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('div', {
          staticClass: "separator separator-dashed"
        }), _c('OrderDetailTable', {
          attrs: {
            "order": row.item,
            "physical-persons": _vm.physicalPersons
          },
          on: {
            "updateStatus": function updateStatus($event) {
              return _vm.refresh();
            }
          }
        })];
      }
    }, {
      key: "cell.created_by",
      fn: function fn(_ref2) {
        var _item$created_by_deta, _item$created_by_deta2, _item$created_by_deta3, _item$created_by_deta4, _item$created_by_deta5;
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Customer")))]), _c('div', {
          staticClass: "text-capitalize d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : (_item$created_by_deta = item.created_by_details) === null || _item$created_by_deta === void 0 ? void 0 : _item$created_by_deta.avatar,
            "avatar-text": item === null || item === void 0 ? void 0 : (_item$created_by_deta2 = item.created_by_details) === null || _item$created_by_deta2 === void 0 ? void 0 : (_item$created_by_deta3 = _item$created_by_deta2.full_name) === null || _item$created_by_deta3 === void 0 ? void 0 : _item$created_by_deta3[0],
            "size": "30px"
          }
        }), _c('div', {
          staticClass: "ml-3 d-flex flex-column"
        }, [_c('span', {
          staticClass: "font-weight-bold font-size-sm"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$created_by_deta4 = item.created_by_details) === null || _item$created_by_deta4 === void 0 ? void 0 : _item$created_by_deta4.full_name) + " ")]), _c('span', {
          staticClass: "text-black-65 font-size-xs text-lowercase"
        }, [_vm._v(_vm._s((_item$created_by_deta5 = item.created_by_details) === null || _item$created_by_deta5 === void 0 ? void 0 : _item$created_by_deta5.email))])])], 1)];
      }
    }, {
      key: "cell.status",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Status")))]), _c('span', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "label label-dot mr-2",
          class: 'label-' + _vm.statusClass(item.status)
        }), _vm._v(" "), _c('span', {
          staticClass: "font-weight-bold text-capitalize",
          class: 'text-' + _vm.statusClass(item.status)
        }, [_vm._v(_vm._s(item.status.replace("_", " ")))])])];
      }
    }, {
      key: "cell.created_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Created at")))]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format(item.created_at, _vm.date_format)) + " ")])];
      }
    }, {
      key: "cell.updated_at",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Updated at")))]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format(item.updated_at, _vm.date_format)) + " ")])];
      }
    }, {
      key: "cell.id",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Order")) + " #")]), _c('span', {
          staticClass: "font-weight-bolder text-black-65 font-size-xs"
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "cell.total_price",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Price")))]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.total_price))])];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }