<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Requested products") }}</h3>
      </div>
    </div>
    <div class="card-body detail order-table">
      <datatable ref="secondmentsOrdersTable" :table-props="tableProps" :options.sync="tableOptions"
        :per-page-options="perPageOptions" :total="total" thead-hidden>

        <template #row-details="{ row }">
          <div class="separator separator-dashed"></div>
          <OrderDetailTable :order="row.item" :physical-persons="physicalPersons"
            @updateStatus="refresh()">
          </OrderDetailTable>
        </template>

        <template #[`cell.created_by`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Customer") }}</div>
          <div class="text-capitalize d-flex align-items-center">
            <Avatar :avatar-image="item?.created_by_details?.avatar"
              :avatar-text="item?.created_by_details?.full_name?.[0]" size="30px"></Avatar>
            <div class="ml-3 d-flex flex-column">
              <span class="font-weight-bold font-size-sm">
                {{ item?.created_by_details?.full_name }}
              </span>
              <span class="text-black-65 font-size-xs text-lowercase">{{ item.created_by_details?.email }}</span>
            </div>
          </div>
        </template>

        <template #[`cell.status`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Status") }}</div>
          <span class="d-flex align-items-center"> <span class="label label-dot mr-2"
              :class="'label-' + statusClass(item.status)"></span> <span class="font-weight-bold text-capitalize"
              :class="'text-' + statusClass(item.status)">{{ item.status.replace("_", " ") }}</span> </span>
        </template>

        <template #[`cell.created_at`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Created at") }}</div>
          <span class="font-weight-bold">
            {{ DateService.format(item.created_at, date_format) }}
          </span>
        </template>

        <template #[`cell.updated_at`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Updated at") }}</div>
          <span class="font-weight-bold">
            {{ DateService.format(item.updated_at, date_format) }}
          </span>
        </template>

        <template #[`cell.id`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Order") }} #</div>
          <span class="font-weight-bolder text-black-65 font-size-xs">
            {{ item.id }}
          </span>
        </template>

        <template #[`cell.total_price`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Price") }}</div>
          <span class="font-weight-bold">{{ item.total_price }}</span>
        </template>

      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import OrdersService from "@/core/services/risk/orders.service";
import icons from "@/core/config/icons";
import { backendErrorSwal } from "@/core/helpers/swal";
import OrderDetailTable from "@/view/components/tables/orders/OrderDetailTable.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";

export default {
  components: {
    OrderDetailTable,
  },
  props: {
    secondment: {
      type: Object,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      icons,
      DateService,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      fields: [
        { label: this.$t("Created at"), key: "created_at", class: 'border-left pl-6 align-top', variant: 'secondary' },
        { label: this.$t("Price"), key: "total_price", class: "align-top", variant: 'secondary' },
        { label: this.$t("Customer"), key: "created_by", class: "align-top", variant: 'secondary' },
        { label: this.$t("ID"), key: "id", class: "align-top", variant: 'secondary' },
        { label: this.$t("Source"), key: "source", class: "align-top", variant: 'secondary' },
        { label: this.$t("Status"), key: "status", class: "align-top border-right pr-6", variant: 'secondary' },
      ],
      total: 0,
      physicalPersons: []
    };
  },
  computed: {
    ...mapGetters("user", ["date_format", "getCurrentManagedCompanyId"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields
      };
    },
    orderIds() {
      const ids = []
      this.secondment.products.items.forEach(element => {
        ids.push(element.order_id)
      });
      return [...new Set(ids)]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("Requested products") },
    ]);
  },
  methods: {
    itemProvider(ctx, callback) {
      OrdersService.get(this.orderIds[0], {
        focus: (this.admin || !this.company) ? null : this.getCurrentManagedCompanyId,
        fields: 'created_by_details,status,created_at,updated_at,items.attributes.name,id,total_price,order_from',
      }).then(res => {
        this.total = this.orderIds.length;
        res.items.forEach(item => {
          if (item?.attributes?.persona) {
            this.getPysicalPersonData(item.attributes.persona);
          }
        })
        callback([{ ...res, _showDetails: true }]);
      }).catch((err) => {
        console.error('@itemProvider err', err);
        this.total = 0;
        callback([]);
      });
    },
    async getPysicalPersonData(id) {
      await PhysicalPersonService.getOne(id, true, 'name,surname').then(res => {
        this.physicalPersons.push({ id: id, name: res.data.name, surname: res.data.surname });
      }).catch(err => {
        console.error('@loadPhysicalPerson', err);
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        if (err.response.status === 404) {
          this.$router.push({ name: '404' }); //unauthorized
          return
        }
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },
    statusClass(status) {
      switch (status) {
        case 'cancelled':
        case 'rejected':
          return 'danger';
        case 'submitted':
          return 'primary';
        case 'draft':
        case 'in_progress':
          return 'warning';
        case 'approved':
          return 'success';
        default:
          return 'danger';
      }
    },
    refresh() {
      const table = this.$refs.secondmentsOrdersTable;
      if (table) {
        table.refresh();
      }
    },
  }
};
</script>

<style scoped>
.categories-cell {
  max-width: 12ch;
}

.categories-span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-customer-width {
  width: 10ch;
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
<style>
.order-table .table.b-table>tbody>tr.b-table-details>td {
  padding-top: 0;
}
</style>
